import React, {useState, useEffect} from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import CaseStudies from '../components/Casestudies/Casestudies.js';
import LanguageContext from "../context/LanguageContext";
import Seo from "../components/seo";
const CasStaideir = ({ data }) => {

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
      setHasMounted(true);
  }, []);

  if(!hasMounted) return null;

  return (
    <LanguageContext.Consumer>
    {language => (
    <Layout>
       <Seo title="Cas-staideir" lang={language.english ? "en" : 'ga'} />
      <CaseStudies categories={data?.categories?.nodes} cards={data?.caseStudies?.nodes} language={language} />
    </Layout>
    )}
    </LanguageContext.Consumer>
    )}


export default CasStaideir

export const pageQuery = graphql`
  query {
caseStudies:allWpCaseStudy(sort: {fields: date, order: DESC}){
	nodes{
    title
    categories{
      nodes{
      name
      caseStudiesCategories{
          categoryNameIrish
        }
      }
    }
    acf_case_study{
    heroBackgroundColour
    titleIrish
    caption
    captionIrish
    heroThumbnail{
			localFile{
        childImageSharp {
          gatsbyImageData( 
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
    }
    googleMap {
      latitude
      longitude
    }
   }
  }
 }

 categories: allWpCategory{
  nodes{
  name
  caseStudiesCategories{
    categoryNameIrish
  }
  parentId
  ancestors {
        nodes {
          id
        }
      }
      }
    }
  }
`
